<template>
  <v-row no-gutters style="background-color: white">
    <v-col cols="12">
      <h3 class="text-center mb-2">2. 차액 수정</h3>

      <p class="mb-1 font-weight-bold text-center">상품 상환표</p>
      <v-simple-table style="border: 1px solid #eeeeee">
        <template v-slot:default>
          <thead>
            <tr>
              <th width="10%" class="text-center">호수</th>
              <th width="20%" class="text-center">총상환금액</th>
              <th width="20%" class="text-center">상환원금합</th>
              <th width="10%" class="text-center">투자자수</th>
              <th width="15%" class="text-center">차액</th>
              <th width="10%" class="text-right">차액지급 여부</th>
              <th width="15%" class="text-right">생성일</th>
            </tr>
          </thead>
          <tbody>
            <template v-if="!!repayments">
              <tr v-for="repayment in repayments" :key="repayment.idx">
                <td class="text-center">{{ repayment.fundingDataIdx }}</td>
                <td class="text-center">
                  {{ repayment.investUserTotalAmount | commaFormat }} 원
                </td>
                <td class="text-center">
                  {{ repayment.investUserPrincipal | commaFormat }} 원
                </td>
                <td class="text-center">
                  {{ repayment.investUserCount | commaFormat }} 명
                </td>
                <td class="text-center">
                  {{ repayment.diffAmount | commaFormat }} 원
                </td>
                <td
                  class="text-right"
                  :class="
                    repayment.isRepaidDiffAmount ? 'green--text' : 'red--text'
                  "
                >
                  {{ repayment.isRepaidDiffAmount ? '지급완료' : '미지급' }}
                </td>
                <td class="text-right">
                  {{ repayment.createTime | dateFormat }}
                </td>
              </tr>
            </template>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>

    <v-col v-if="repaymentIdxes.length > 0" cols="12" class="mb-5">
      <v-btn
        class="mt-3"
        large
        :dark="!isDoneExec"
        :loading="isLoading"
        :disabled="isLoading || isDoneExec"
        @click="modifyRpyDiff()"
      >
        차액 수정하기
      </v-btn>
      <p v-if="isDoneExec" class="mt-2 mb-0 red--text">
        차액이 수정되었습니다.
      </p>
    </v-col>

    <v-col cols="12">
      <h3 class="text-center mb-2">3. 차액 지급</h3>
    </v-col>

    <v-col v-if="repaymentIdxes.length > 0" cols="12">
      <v-btn
        class="mt-3"
        large
        :dark="!execDisabled"
        :loading="isLoading"
        :disabled="execDisabled"
        @click="execRpyDiff()"
      >
        차액 지급하기
      </v-btn>

      <p v-if="isDoneExec" class="mt-2 mb-0 red--text">
        차액 지급이 완료되었습니다.
      </p>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    repayments: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      isLoading: false,
    }
  },
  computed: {
    repaymentIdxes() {
      const result = this.repayments?.map((r) => r.idx)
      return result?.length > 0 ? result : []
    },
    isDoneExec() {
      return this.repayments?.every((r) => r.isRepaidDiffAmount === true)
    },
    execDisabled() {
      return this.isLoading || this.isDoneExec
    },
  },
  methods: {
    async modifyRpyDiff() {
      if (this.repaymentIdxes.length < 1) {
        return
      }

      const confirm = await this.$swal.basic.confirm('차액을 수정합니다.')
      if (confirm.isConfirmed === false) {
        return
      }

      // const fundingDataIdx = this.dongsan.parent
      this.isLoading = true

      const q = 'data: modifyRepaymentDiffAmount(repaymentIdxes: $input)'

      try {
        await this.$fundaApi.mutation(gql`{${q}}`, 'call($input: [Long]!)', {
          input: this.repaymentIdxes,
        })
      } finally {
        this.isLoading = false
      }

      this.$emit('emitGetRepayments')
    },
    async execRpyDiff() {
      const confirm = await this.$swal.basic.confirm('차액지급을 실행합니다.')
      if (confirm.isConfirmed === false) {
        return
      }

      this.isLoading = true

      for (const rpy of this.repayments) {
        if (rpy.isRepaidDiffAmount === false) {
          const rpyIdx = rpy.idx
          const q = `
            data: execRepaymentDiffAmount(fundingDataRepaymentIdx: ${rpyIdx}){
              isRepaidDiffAmount
            }
          `
          const data = await this.$fundaApi.mutation(gql`{${q}}`)

          rpy.isRepaidDiffAmount = data.isRepaidDiffAmount
        }
      }

      this.isLoading = false

      this.$emit('emitGetRepayments')
    },
  },
}
</script>
